<template>
  <div class="home">
      <!-- <div id="important-msg">
        <p>
          <b>Important info:</b> Due to an unforseen technical issue, some Rebel Selfie competition entries have been lost. The deadline for submissions has been extended by one week to allow for resubmissions. Please email your entries to <b>rebelselves@gmail.com</b>
        </p>
      </div> -->
      <p>
        The #Rebel Selves App is designed to make your selfies glitchy and fun!
      </p>

      <p>
        It was created by <a href="https://charlotteroe.space/" target="_blank" rel="noopener noreferrer">Charlotte Roe</a>, an artist and developer based in Huddersfield, and <a href="https://www.dawnwoolley.com/" target="_blank" rel="noopener noferrer">Dr Dawn 
        Woolley</a>, an artist and research fellow at Leeds Arts University, with support from Leeds Arts University and the HE Innovation Fund.
      </p>

      <router-link to="/app">
        <button class="button-primary">Go to App</button>
      </router-link>

      <!-- <section id="competition" class="info">
        <h2>Release Your Inner Rebel Selfie Competition</h2>
        <p>
          Dawn researches social networking sites and selfies. Because it is difficult to achieve beauty ideals, selfie taking can cause low self-esteem and body image issues. 
          The app is one of the creative approaches she is developing to challenge gender and beauty ideals and reduce these risks.
        </p>
        <p>
          To launch the app and share her important research, she is running <b>Release Your Inner Rebel Selfie Competition</b> to find the most creative images 
          that can be produced using the app.
        </p>
        <p>
          The Release Your Inner Rebel Selfie Competition is free and very easy to enter.
          Participants create selfies using the <a href="http://www.rebelselves.com/#/app" target="_blank" rel="noopener noreferrer">app</a> send them to
          rebelselves@gmail.com or tag @rebelselves on Instagram and use #rebelselfie tag
          in the post.
        </p>
        <p>
          Winning entries will be shared online and exhibited in a poster exhibition around
          Leeds (Sponsorship kindly provided by Pop Art).
        </p>
        <p>
          Dawn is running some free online workshops to introduce the competition and the
          app. Sign up here <a href="https://www.eventbrite.co.uk/e/release-your-inner-rebel-selfie-competition-workshops-tickets-946353116647" target="_blank" rel="noopener noreferrer">
          eventbrite.co.uk/e/release-your-inner-rebel-selfie-competition-workshops-tickets-946353116647</a> if you’d like to learn more.
        </p>
        <p>The deadline to enter is Sunday 20th October 2024</p>
        <p>Winners will be announced on Saturday 23rd November</p>
        <h3>Our exciting panel of expert judges are:</h3>
          <p>Maya Fuhr, fashion photographer and philanthropist in LA</p>
          <p>Dr Michael Petry, artist, author and Director of the Museum of Contemporary Art (MOCA) London</p>
          <p>Jung Me Chai, gallerist and founder of Diskurs Berlin</p>
          <p>Mindy Lee, artist and curator of Blyth Gallery London</p>
          <p>Matthew Pendergast, Curator and Deputy Director, Castlefield Gallery, Manchester</p>
        <h3>Prizes:</h3>
          <p><b>First Prize:</b> £100 Amazon voucher from Leeds Arts University</p>
          <p><b>Second Prize:</b> £50 Voucher for Take It Easy Photo Lab</p>
          <p><b>Third Prize:</b> £30 Curator Space Artist subscription</p>
          <p><b>Fourth Prize:</b> MirrorMirror: The Reflective Surface in Contemporary Art By Michael Petry (brand new! Published by Thames and Hudson on 14 November 2024)</p>
          <p>More to be announced</p>
        <p>Thank you to these organisations for their providing these amazing prizes.</p>
        <h3>Terms and Conditions</h3>
        <p>The judges decisions are final.</p>
        <p>Prizes cannot be swapped.</p>
        <p>Images deemed to be offensive or depict risk to the selfie-taker / others will not be shared.</p>
        <p>Any questions can be directed to Dawn at dawn.woolley@leeds-art.ac.uk</p>
      </section> -->
    
      <section id="about">
        <h2>About RebelSelves</h2>
        <p>
        Dr Woolley researches gender and beauty ideals on social networking sites and in selfies. 
        The app was developed in response to the conclusions of her book <a href="https://www.bloomsbury.com/uk/consuming-the-body-9781350225299/" target="_blank" rel="noopener noreferrer">Consuming the Body: Capitalism, 
        social media and commodification</a> (Bloomsbury, 2023). The book examines how advertising and 
        selfies impact on bodies, health, and gender norms, and the conclusion offers some creative ways 
        in which selfies might disrupt gender norms and beauty ideals. The creative ways include:
      </p>

      <h2>Movement / Action:</h2>
      <p>
        Rather than concentrate on how the body looks, selfies that capture activity by montaging multiple poses / actions 
        would show what the body can do.
      </p>

      <h2>Glitch / Blur / Pixelation:</h2>
      <p>
        Through montage, glitch, and visual obstructions in the form of overlay textures, frames and stickers, 
        selfies may represent bodies in ways that are difficult to decode. Individuals can be visible and masked, 
        preventing categorisation and comparison against white, ableist, thin, and heteronormative body ideals.
      </p>

      <h2>Queer Timelines:</h2>
      <p>
        Social networking sites encourage users to present their life as a series of Instagramable events in a Timeline. 
        Montaged selfies could disrupt the ‘eventification’ of life and also resist the message we should aim to 
        transform ourselves into a perfect, finished, and ideal body expressed in ‘before’ and ‘after’ photographs in advertising. 
        Instead, they would allow for fluid identities and more complex narratives. 
      </p>

      <h2>Multiplicity:</h2>
      <p>
        Meg-John Barker and Alex Iantaffi suggest that using both/and thinking will help us to escape the binarism of 
        either/or thinking that pits female against male, healthy against unhealthy, fat against thin, and good against bad 
        (Life Isn’t Binary: On Being Both, Beyond, and In-Between, Jessica Kingsley Publishers, 2019). In selfies we could 
        create montages or series of images showing different aspects of identity, lifestyle, pleasures, and displeasures. 
        They could also blend the self with different objects, places, and people, to give a complex impression of life in a moment in time.
      </p>
      </section>
  </div>
  <Footer />
</template>

<script>
import Footer from '../components/Footer.vue';

  export default {
    components: { Footer },
    created(){
        document.title = 'RebelSelves';
    }
  }
</script>

<style scoped>
#important-msg {
  background-color: white;
  margin: 5px;
}

#important-msg > p {
  margin: 0px;
  padding: 10px;
}

.home p {
  margin: 20px 25vw;
}

@media (max-width: 1100px) {
  .home p {
    margin: 20px 10vw;
  }
}

ul {
  list-style: none;
}

#competition {
  margin: 5vh 20vw;
}

#competition > * {
  margin: 20px;
}

@media (max-width: 750px) {
  #competition {
    margin: 2vh 5vw;
  }
}

</style>