<template>
        <div class="footer">
            <p>Supported by Leeds Arts University</p>
            <img src="../assets/LAU_pink.jpg" alt="LAU Logo">
        </div>
</template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>